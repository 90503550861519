import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react';
import { useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { useParams } from 'react-router-dom';
import { fetcher } from '../../fetcher'
import { FileField } from '../../modules/createProduct/createProduct';
import { ProductInt } from '../../store/products';
import { toast } from 'react-toastify';
import { $auth } from '../../store/auth';


const setCategories = createEvent();
const $categories = createStore(null).on(setCategories, (a, b) => {
    return b;
});

const setProduct = createEvent();
const $product = createStore<null | ProductInt>(null).on(setProduct, (a, b) => { //TODO нет интерфейса
    return b;
});

export const EditProduct = () => {

    let params = useParams();
    let productID = params?.id;

    const categories = useStore($categories);
    const product = useStore($product);
    useEffect(() => {
        (async () => {
            const { data } = await fetcher.get_categories();
            setCategories(data);
        })();
        (async () => {
            const { data } = await fetcher.get_product({
                product: productID
            });
            setProduct(data);
        })();
    }, [productID]);

    return <>
        {product && categories && <>
            <h1>Редактируем {product.name}</h1>
            <ProductForm categories={categories} product={product} />
        </>}
    </>
}


const ProductForm = ({ categories, product }: { categories: any; product: ProductInt }) => {
    let auth = useStore($auth);

    return <Form
        initialValues={{
            // ...(() => {
            //     if (process.env.NODE_ENV === "development") {
            //         return {
            id: product.id,
            category_id: product.category_id,
            name: product.name,
            code: product.code,
            purchase_price: product.purchase_price,
            cost_type: product.cost_type,
            cost_value: product.cost_value,
            note: product.note,
            archive: product.archive,
            retail_price_type: product.retail_price?.price_type,
            retail_price_value: product.retail_price?.price_count,
            stock_khv: product.stock.find((shop: any) => shop.shop_id === "khv")?.count,
            stock_bir: product.stock.find((shop: any) => shop.shop_id === "bir")?.count,
            stock_blg: product.stock.find((shop: any) => shop.shop_id === "blg")?.count,
            description: product.description,
            //         }
            //     }
            //     return {}
            // })(),

        }}
        onSubmit={values => onSubmit(values, product)}
        render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
                <div className="">
                    <FormSection
                        name="Наименование товара"
                        component={<Field name="name" req>{props => (<div>
                            <input {...props.input} placeholder="Наименование" className="form-control" disabled={auth?.user.id !== "1"} />
                        </div>)}</Field>}
                    />
                    <FormSection
                        name="Категория"
                        component={<Field name="category_id" component="select" disabled={auth?.user.id !== "1"}>
                            {({ input }) =>
                                <select {...input} className="form-control form-select" disabled={auth?.user.id !== "1"}>
                                    <option value="">Выберите категорию</option>
                                    {Array.isArray(categories) && categories
                                        .map((x: any) =>
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>}
                    />
                    {auth?.user.id === "1" && <FormSection
                        name="Изображение"
                        component={<FileField name="image" />}
                    />}
                    <FormSection
                        name="Артикул"
                        component={<Field name="code" req>{props => (<div><input {...props.input} placeholder="Артикул" className="form-control" disabled={auth?.user.id !== "1"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Закупочная цена"
                        component={<Field name="purchase_price" req>{props => (<div><input {...props.input} placeholder="Закупочная цена" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Тип себестоимости"
                        component={<Field name="cost_type" component="select">
                            {({ input }) =>
                                <select {...input} className="form-control form-select" disabled={auth?.user.id !== "1"}>
                                    <option value="">Выберите тип себестоимости</option>
                                    {[
                                        { id: "percent", name: "Процент" },
                                        { id: "handle", name: "Ручной" },
                                        { id: "fix", name: "Фиксированный" },
                                    ]
                                        .map((x: any) =>
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>}
                    />
                    <FormSection
                        name="Значение себестоимости"
                        component={<Field name="cost_value" req>{props => (<div><input {...props.input} placeholder="Значение себестоимости" className="form-control" type={"number"} disabled={auth?.user.id !== "1"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Комментарий"
                        component={<Field name="note" req>{props => (<div><input {...props.input} placeholder="Комментарий" className="form-control" disabled={auth?.user.id !== "1"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="В архиве"
                        component={<Field name="archive" component="select" >
                            {({ input }) =>
                                <select {...input} className="form-control form-select" aria-label="Default select example" disabled={auth?.user.id !== "1"}>
                                    <option value="">Выберите статус товара</option>
                                    {[
                                        { id: "1", name: "В архиве" },
                                        { id: "0", name: "В работе" },
                                    ]
                                        .map((x: any) =>
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>}
                    />

                    <>
                        <FormSection
                            name="Тип розничной цены"
                            component={<Field name="retail_price_type" component="select" >
                                {({ input }) =>
                                    <select {...input} className="form-control form-select" aria-label="Default select example" disabled={auth?.user.id !== "1"}>
                                        <option value="">Выберите тип</option>
                                        {[
                                            { id: "percent", name: "Процент" },
                                            { id: "handle", name: "Ручной" },
                                            { id: "fix", name: "Фиксированный" },
                                        ]
                                            .map((x: any) =>
                                                <option value={x.id} key={x.id}>{x.name}</option>
                                            )}
                                    </select>}
                            </Field>}
                        />
                        <FormSection
                            name="Значение розничной цены"
                            component={<Field name="retail_price_value" req>{props => (<div><input {...props.input} placeholder="" className="form-control" type={"number"} disabled={auth?.user.id !== "1"} /></div>)}</Field>}
                        />
                    </>

                    <FormSection
                        name="В наличии в Хабаровске"
                        component={<Field name="stock_khv" req>{props => (<div><input {...props.input} placeholder="В наличии в Хабаровске" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="В наличии в Биробиджане"
                        component={<Field name="stock_bir" req>{props => (<div><input {...props.input} placeholder="В наличии в Биробиджане" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="В наличии в Благовещенске"
                        component={<Field name="stock_blg" req>{props => (<div><input {...props.input} placeholder="В наличии в Благовещенске" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Описание"
                        component={<Field name="description" req>{props => (<div><textarea {...props.input} placeholder="Описание" className="form-control" disabled={auth?.user.id !== "1"} /></div>)}</Field>}
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-sm mt-2">Сохранить</button>
            </form>
        )}
    />
}





const FormSection = ({ name, component }: any) => {
    return <div className="row mb-3">
        <div className="col-md-2 lh-1">
            <strong>{name}</strong>
        </div>
        <div className="col-md-4">
            {component}
        </div>
    </div>
}

function onSubmit(values: any, initialValues: any) {

    const initialKhvStock = Number(initialValues.stock.find((shop: any) => shop.shop_id === "khv")?.count);
    const newKhvStock = Number(values.stock_khv);


    const initialBirStock = Number(initialValues.stock.find((shop: any) => shop.shop_id === "bir")?.count);
    const newBirStock = Number(values.stock_bir);

    const initialBlgStock = Number(initialValues.stock.find((shop: any) => shop.shop_id === "blg")?.count);
    const newBlgStock = Number(values.stock_blg);

    // console.log('$auth', Number($auth?.getState()?.user.role)===1);

    if (Number($auth?.getState()?.user.role) !== 1) {
        if (newKhvStock < initialKhvStock) {
            toast.error("Недостаточно полномочий");
            return;
        }
        if (newBirStock < initialBirStock) {
            toast.error("Недостаточно полномочий");
            return;
        }
        if (newBlgStock < initialBlgStock) {
            toast.error("Недостаточно полномочий");
            return;
        }
    }

    const formData = new FormData();
    Object.entries(values).forEach((r: any) => {
        if (r[0] === "image") {
            formData.append(r[0], r[1][0]);
            return null;
        }
        formData.append(r[0], r[1]);
    })
    fetcher.edit_product(formData).then(res => {
        if (res?.data?.success) {
            toast.success("Успешно");
        } else {
            toast.success("Что-то пошло не так " + JSON.stringify(res?.data?.errors, null, " "));
        }
    });

}

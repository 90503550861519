import axios from "axios";
import { setAuth } from "./store/auth";
import url from "./url";
import { toast } from 'react-toastify';
// alert()

export const fetcherFn = async (params: any) => axios({
  params
}).then(x => {
  let returnState = null;

  if (typeof !x?.data === "string" && !x?.data?.length) {
    toast.error('Что-то пошло не так ');
    returnState = null;
  }
  try {
    returnState = x.data;
  } catch (error) {
    toast.error('Что-то пошло не так ');
    returnState = null;
  }
  return returnState;


}).catch(reason => {
  toast.error('Что-то пошло не так ');
  return null;
});


export const fetcher = {
  sign_in: (values: { email: string; }) => {
    axios({
      method: "POST",
      data: values,
      url: url + "?service=sign_in"
    })
      .then((res: any) => {
        if (res?.data) {
          if (res?.data?.success) {
            setAuth(res.data)
          } else {
            toast.error('Что-то Вы ввели не так');
          }
        }
      });
  },
  get_products: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=get_products"
    })
  },
  get_product: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=get_product&v=0.0"
    })
  },
  get_categories: () => {
    return axios({
      method: "POST",
      url: url + "?service=get_categories&v=0.0"
    })
  },
  create_product: (values: any) => {
    return axios({
      method: "POST",
      data: values,
      headers: {
        'content-type': 'multipart/form-data'
      },
      url: url + "?service=create_product&v=0.0"
    })
  },
  edit_product: (values: any) => {
    return axios({
      method: "POST",
      data: values,
      url: url + "?service=edit_product&v=0.0"
    })
  },
  delete_product: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=delete_product&v=0.0"
    })
  },
  create_category: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=create_category&v=0.0"
    })
  },
  get_category: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=get_category&v=0.0"
    })
  },
  edit_category: (values: any) => {
    return axios({
      method: "POST",
      data: values,
      url: url + "?service=edit_category&v=0.0"
    })
  },
  send_cart: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=send_cart&v=0.0"
    })
  },
  search: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=search&v=0.0"
    })
  },
  get_orders: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=get_orders&v=0.0"
    })
  },
  confirm_order: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=confirm_order&v=0.0"
    })
  },
  decline_order: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=decline_order&v=0.0"
    })
  },
  sell: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=sell&v=0.0"
    })
  },
  login: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=login&v=0.0"
    })
  },
  get_investments: () => {
    return axios({
      method: "GET",
      url: url + "?service=get_investments&v=0.0"
    })
  },
  check_auth: (params: any) => {
    return axios({
      method: "POST",
      data: params,
      url: url + "?service=check_auth&v=0.0"
    })
  },
}
import { useStore } from "effector-react";
import { FaCartPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { $cartStore, cartStoreAPI } from "../../routes/cart/store";
import { ProductInt } from "../../store/products";
import url from "../../url";
import noimageSrc from "../../../media/noimage.png";
import styled, { css } from "styled-components";
import { FiAlertTriangle } from "react-icons/fi"
import { fetcher } from "../../fetcher";
import { $auth } from "../../store/auth";
import { getDataFrom } from "../products/products";
import { getPriceFromProduct } from "../product/product";
import { toast } from "react-toastify";

export const ProductCard = ({ product }: { product: ProductInt }) => {
    const cartStore = useStore($cartStore);
    const auth = useStore($auth);

    const image = product.image_preview ? `${url}images/${product.image_preview}` : noimageSrc;

    return <div className="col-12 col-sm-6 col-md-4 col-xl-3  shadow d-flex flex-column justify-content-between p-2" >
        <Link to={`/product/${product.slug}`} className="link-dark text-decoration-none animated tada infinite">
            <div className="p-2">
                <div>
                    <Background url={image} />
                </div>
                <h5 className="card-title py-2 mb-2 border-bottom">{product.name}</h5>
                <div>
                    <div><strong>Код товара</strong> {product.code}</div>
                    <div><strong>Категория</strong> {product.category_name}</div>
                    {auth && <>
                        <div><strong>Закупочная цена</strong> {product.purchase_price}</div>
                        <div>
                            <strong>Тип себестоимости</strong> {product.cost_type}
                        </div>
                        <div>
                            <strong>Значение себестоимости</strong> {product.cost_value}
                        </div>
                        <div>
                            <strong>Себестоимость </strong>
                            <>
                                {(() => {
                                    let first_cost = null; if (product.cost_type === "percent") { first_cost = Number(product.purchase_price) * Number(product.cost_value) }; if (product.cost_type === "handle") { first_cost = Number(product.cost_value) }; if (product.cost_type === "fix") { first_cost = Number(product.purchase_price) + Number(product.cost_value) };
                                    return first_cost;
                                })()}
                            </>
                        </div>
                        <div>
                            <strong>Тип розничной цены</strong> {product.retail_price.price_type}
                        </div>
                        <div>
                            <strong>Значение розничной цены</strong> {product.retail_price.price_count}
                        </div>
                        <div>
                            <strong>Комментарий</strong> {product.note}
                        </div>
                        <div>
                            <strong>Продано в Ха</strong> {product.sold_khv}
                        </div>
                        <div>
                            <strong>Продано в Бир</strong> {product.sold_bir}
                        </div>
                    </>}

                    <div>
                        <div>
                            <strong>Цена </strong>
                            {getPriceFromProduct(product)}
                        </div>
                    </div>

                    <div>
                        {[
                            ["В наличии в Хабаровске", "khv"],
                            ["В наличии в Биробиджане", "bir"],
                            ["В наличии в Благовещенске", "blg"],
                        ].map((city, i) => <div key={i}>
                            <strong>{city[0]} </strong>
                            {(() => {
                                const stock_item = product.stock
                                    .find(({ shop_id }) => shop_id === city[1]);
                                const val = stock_item?.count ? stock_item?.count : 0;
                                return <>{val} {Number(val) === 0 && <FiAlertTriangle color="red" size={"1.5em"} />}</>;
                            })()}

                        </div>)}
                    </div>
                    <div>
                        {(product.description && product.description !== "null") && <><strong>Описание</strong> <pre>{product.description}</pre></>}
                        {/* <strong>Описание</strong> <pre>{(product.description && product.description !== "null") ? product.description : ""}</pre> */}
                    </div>

                    {/* <div>
                        <pre>{JSON.stringify(product, null, 2)}</pre>
                    </div> */}

                </div>
            </div>
        </Link>
        <div className="p-2 align-items-center d-flex">


            {(() => {
                const stockArr = product.stock.map(x => Number(x.count));
                if (!stockArr.length) return null;
                const in_stock = stockArr.reduce((a, b) => a + b);
                if (in_stock) {
                    return <button className="btn btn-sm btn-primary"
                        onClick={() => {
                            cartStoreAPI.addProduct(product);
                        }}
                    >В корзину</button>
                }
            })()}


            {(() => {
                const currentProduct = cartStore.find(cartProduct => cartProduct.slug === product.slug);
                if (currentProduct) {
                    return <div className="ms-3 d-flex align-items-center">
                        <FaCartPlus />
                        <span style={{ fontSize: "1.5em" }}>{currentProduct.count}</span>
                    </div>;
                }
                return null;
            })()}
        </div>
        {auth && <div className="mt-2">
            <div>
                <button className="btn btn-sm btn-primary"
                    onClick={() => {
                        fetcher.sell({ product: product.id, shop: "khv", price: getPriceFromProduct(product) }).then(x => {
                            if (x?.data?.success) {
                                toast.success("Успешно");
                                getDataFrom();
                            } else {
                                toast.error('Что-то пошло не так ');
                            }
                        });
                    }}
                >Продать в Хабаровске</button>
            </div>
            <div>
                <button className="btn btn-sm btn-primary mt-2"
                    onClick={() => {
                        fetcher.sell({ product: product.id, shop: "bir", price: getPriceFromProduct(product) }).then(x => {
                            if (x?.data?.success) {
                                toast.success("Успешно");
                                getDataFrom();
                            } else {
                                toast.error('Что-то пошло не так ');
                            }
                        });
                    }}
                >Продать в Биробиджане</button>
            </div>
            <div>
                <button className="btn btn-sm btn-primary mt-2"
                    onClick={() => {
                        fetcher.sell({ product: product.id, shop: "blg", price: getPriceFromProduct(product) }).then(x => {
                            if (x?.data?.success) {
                                toast.success("Успешно");
                                getDataFrom();
                            } else {
                                toast.error('Что-то пошло не так ');
                            }
                        });
                    }}
                >Продать в Благовещенске</button>
            </div>
        </div>}
    </div >
}

const Background: any = styled.div`
    ${(props: any) => {
        return css`
            width: 100%;
            height: 300px;
            background: #eee;
            background-position: center;
            background-size: cover;
            background-image: url(${props.url})
        `
    }}
`
import { createApi, createStore } from "effector";
import { ProductInt } from "../../store/products";

interface StoreType {
    slug: string;
    code: string;
    name: string;
    count: number;
    price: number;
};

export const $cartStore = createStore<StoreType[]>([
    // {
    //     "slug": "nuzhnaqzapchastx",
    //     "count": 1,
    //     "name": "Нужная запчасть"
    // }
]);

export const cartStoreAPI = createApi($cartStore, {
    addProduct: (store, product: ProductInt) => {
        const arr = [...store];
        const index = store.findIndex(storeProduct => storeProduct.slug === product.slug);
        if (index >= 0) {
            arr[index] = { ...arr[index], count: arr[index].count + 1 };
            return arr;
        } else {
            return [...store,
            {
                slug: product.slug, count: 1, name: product.name, code: product.code,
                price: (() => {

                    const first_cost = (() => {
                        if (product != null) {
                            let first_cost = null; if (product.cost_type === "percent") { first_cost = Number(product.purchase_price) * Number(product.cost_value) }; if (product.cost_type === "handle") { first_cost = Number(product.cost_value) }; if (product.cost_type === "fix") { first_cost = Number(product.purchase_price) + Number(product.cost_value) };
                            return first_cost;
                        }
                    })();

                    let retail_cost = null;
                    if (first_cost && product.retail_price) {
                        const shop_price_item: any = product.retail_price;
                        if (shop_price_item.price_type === "percent") {
                            retail_cost = Number(first_cost) * Number(shop_price_item.price_count)
                        };
                        if (shop_price_item.price_type === "handle") { retail_cost = Number(shop_price_item.price_count) };
                        if (shop_price_item.price_type === "fix") { retail_cost = Number(first_cost) + Number(shop_price_item.price_count) };
                    }
                    return Math.ceil(retail_cost ? retail_cost : 0);
                })()
            }
            ];
        }
    },
    removeProduct: (store, productSlug: string) => {
        return store.filter(product => product.slug !== productSlug);
    },
    increment: (store, productSlug: string) => {
        const arr = [...store]; const index = store.findIndex(storeProduct => storeProduct.slug === productSlug); if (index >= 0) { arr[index] = { ...arr[index], count: arr[index].count + 1 }; return arr; }
    },
    decrement: (store, productSlug: string) => {
        const arr = [...store]; const index = store.findIndex(storeProduct => storeProduct.slug === productSlug); if (index >= 0) { arr[index] = { ...arr[index], count: arr[index].count > 0 ? arr[index].count - 1 : 0 }; return arr; }
    },
    clear: () => ([]),
})

// $cartStore.watch(x => {
//     console.log('cart', x);
// })
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { Field, Form } from "react-final-form"
import { fetcher } from "../../fetcher"
import { setHide } from "../../modules/header/searchPanel";
import { ProductCard } from "../../modules/productCard/productCard";
import { ProductInt } from "../../store/products";
import { toast } from "react-toastify";

const setStore = createEvent();
const $store = createStore<ProductInt[] | null>(null).on(setStore, (_, v) => v);

export const setSearchInitStore = createEvent<{ keyword: string }>();
const $searchInitStore = createStore<{ keyword: string } | null>(null).on(setSearchInitStore, (_, v) => v);

export const getProductsSearch = (values: any) => {
    fetcher.search(values).then(x => {
        if (x?.data) {
            setStore(x.data);
        } else {
            toast.error("Что-то пошло не так");
        }
    })
}

export const Search = () => {

    useEffect(() => {
        setHide(true);
    }, [])
    useEffect(() => () => {
        setHide(false);
    }, []);

    const store = useStore($store);
    const searchInitStore = useStore($searchInitStore);

    return <>
        <h1>Поиск запчасей</h1>
        <>
            <Form
                initialValues={{
                    ...(() => {
                        if (searchInitStore) return searchInitStore;
                        return {}
                    })()
                }}
                validate={values => {
                    const errors: any = {}
                    if (!values.keyword) {
                        errors.keyword = 'Required'
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    getProductsSearch(values)
                }}
                render={({ handleSubmit }) => {
                    return <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-align-center">
                            <Field component={"input"} name="keyword" placeholder="Поиск" />
                            <button className="btn btn-sm btn-primary ms-2">Поиск</button>
                        </div>
                    </form>
                }}
            />
            {Array.isArray(store) && <div className="d-flex flex-wrap mt-3">
                {store.length ?
                    store.map((product, index) => <ProductCard product={product} key={index} />) :
                    <>Ничего не найдено</>}
            </div>}
        </>
    </>
}
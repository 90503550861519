import { Form, Field } from 'react-final-form'
import { fetcher } from '../fetcher'
import { logout, $auth } from '../store/auth';
import { useStore } from "effector-react"

export const LoginRoute = () => {
    const auth = useStore($auth);
    if (auth) return <div>
        <div>Здравствуйте, {auth.user.name}!</div>
        <button className='btn btn-sm btn-danger'
            onClick={()=>{logout()}}
        >Выйти</button>
    </div>
    return <div>
        <MyForm />
    </div>
}

const MyForm = () => (
    <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <h3>Войти</h3>
                <div className="d-flex">
                    <Field name="login" req>
                        {props => (
                            <div>
                                <input {...props.input} placeholder="Логин" className="form-control" />
                            </div>
                        )}
                    </Field>
                    <Field name="password" type="password" >
                        {props => (
                            <div>
                                <input {...props.input} placeholder="Пароль" className="form-control" />
                            </div>
                        )}
                    </Field>
                </div>
                <button type="submit" className="btn btn-primary btn-sm mt-2">вход</button>
            </form>
        )}
    />
)



function onSubmit(values: {
    email: string;
    password: string;
}) {
    fetcher.sign_in(values);
}
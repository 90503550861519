import { Editor, EditorState, RichUtils, CompositeDecorator, ContentState, convertFromHTML } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useEffect, useMemo, useState } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { AiOutlineLink } from 'react-icons/ai';

const Link = (props: any) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} >
            {props.children}
        </a>
    );
};

function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
    contentBlock.findEntityRanges(
        (character: any) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

export const DescriptionEditor = ({ initialValue, setValue, placeholder }: any) => {


    const decorator = useMemo(() => new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]), []);


    const [editorState, setEditorState] = useState<any>(
        () => EditorState.createEmpty(decorator),
    );

    useEffect(() => {
        if (initialValue) {
            const blocksFromHTML = convertFromHTML(initialValue);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(
                state,
                decorator,
            ));
        }

    }, [initialValue, decorator]);


    useEffect(() => {
        EditorState.createWithContent(ContentState.createFromText('Hello'))
    }, [initialValue])

    useEffect(() => {
        let html = stateToHTML(editorState.getCurrentContent());
        setValue(html);
    }, [editorState, setValue]);

    const onAddLink = () => {
        const selection = editorState.getSelection();
        const link = window.prompt("Paste the link -");
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
            return "handled";
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
            url: link
        });
        const newEditorState = EditorState.push(
            editorState,
            contentWithEntity,
            // @ts-ignore: Unreachable code error
            "create-entity"
        );
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    };

    return <>
        <div className="form-control">
            <Editor
                editorState={editorState}
                onChange={setEditorState}
                placeholder={placeholder}
            />
        </div>
        <div className='btn btn-sm btn-outline-dark mt-2' onClick={onAddLink}>
            Прикрепить ссыль <AiOutlineLink size={"1.5em"} />
        </div>
    </>;
}

import { useStore } from "effector-react"
import { $auth, setAuth } from "./store/auth"
import { useEffect } from "react";
import { fetcher } from "./fetcher";

export const AuthChecker = ({ children }: { children: React.ReactNode }) => {
    const auth = useStore($auth);
    useEffect(() => {
        if (auth === null) {
            const mylocalstorage = localStorage.getItem("auth");
            if (mylocalstorage != null) {
                fetcher.check_auth(
                    mylocalstorage
                )
                    .then(x => {
                        if (x.data.success) {
                            localStorage.setItem('auth', JSON.stringify({
                                auth: true,
                                token: x.data.data.token,
                                user: x.data.data.user
                            }));
                            setAuth(x.data.data);
                        }
                    });
            }

        }
    }, [auth]);
    return <div>{children}</div>
}
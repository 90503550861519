import { useStore } from "effector-react";
import { Form as ReactFinalForm, Field } from 'react-final-form'
import { fetcher } from "../../fetcher";
import { toast } from "react-toastify";
import { $auth, setAuth } from "../../store/auth";


export const Login = () => {
    const auth = useStore($auth);


    if (auth) return <>
        <div>Добро пожаловать, {auth?.user?.name}!</div>
    </>

    return (
        <>
            <ReactFinalForm
                validate={values => {
                    const errors: any = {}
                    if (!values.login) {
                        errors.login = 'Required';
                    }
                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    return errors
                }}
                onSubmit={onSubmit}
                render={({ handleSubmit, values }: any) => (
                    <form onSubmit={handleSubmit}>
                        <h3>Войти</h3>
                        <div className="d-flex">
                            <Field name="login" req>
                                {props => (
                                    <div>
                                        <input {...props.input} placeholder="Логин" className="form-control" />
                                    </div>
                                )}
                            </Field>
                            <Field name="password" type="password" >
                                {props => (
                                    <div>
                                        <input {...props.input} placeholder="Пароль" className="form-control" />
                                    </div>
                                )}
                            </Field>
                        </div>
                        <button type="submit" className="btn btn-primary btn-sm mt-2">вход</button>
                    </form>
                )}
            /></>
    )
}

async function onSubmit(a: any) {
    var errors = [];
    if (!a.login) errors.push('введите логин');
    if (!a.password) errors.push('введите пароль');
    if (errors.length) {
    };
    await fetcher.login({
        login: a.login,
        password: a.password,
    }).then(x => {
        if (x?.data?.success) {
            if (x?.data?.data) {
                localStorage.setItem('auth', JSON.stringify({
                    auth: true,
                    token: x.data.data.token,
                    user: x.data.data.user
                }));
                setAuth({
                    auth: true,
                    token: x.data.data.token,
                    user: x.data.data.user
                });
            }
        } else {
            toast.error("Что-то пошло не так");
        }
    });

}
import { FaShoppingCart } from "react-icons/fa"
import { Categories } from "./categories"
import { Menu } from "./menu"
import { $cartStore } from "../../routes/cart/store"
import { useStore } from "effector-react"
import { Link } from "react-router-dom"
import logoSVG from "../../../media/logo.svg"
import { AiFillYoutube, AiOutlineWhatsApp, AiOutlineInstagram } from "react-icons/ai"
import { FaViber, FaTelegramPlane } from "react-icons/fa"
import { $auth } from "../../store/auth"

export const Header = () => {
    const auth = useStore($auth);
    const cartStore = useStore($cartStore);
    return <header className="mb-3">

        {auth && <div className="bg-dark py-3" style={{ borderBottom: "1px solid #fff" }}>
            <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-white">
                        <Menu />
                    </div>
                </div>
            </div>
        </div>}

        <div className=" py-3" style={{ background: "#343a40" }}>
            <div className="container">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="my-3">
                        <a href="/" className="logo_a logo-text">
                            <img src={logoSVG} alt="МотоХит.ЗИП" style={{ width: "300px" }} />
                        </a>
                    </div>
                    <div className="d-flex align-items-end flex-wrap">
                        <div style={{ whiteSpace: "nowrap" }} className="my-3 me-4">
                            <div><span className="text-white">Хабаровск, Нововыборгская, 48</span></div>
                            <div>
                                <a href="tel:+7 (914) 545–92–79" className="phone text-white">+7 (914) 545–92–79</a>
                                <AiOutlineWhatsApp color="#5ec244" className="ms-2" />
                                <FaViber color="#8b66a8" className="ms-2" />
                                <FaTelegramPlane color="white" className="ms-2" />
                            </div>
                        </div>
                        <div style={{ whiteSpace: "nowrap" }} className="my-3 me-4">
                            <div><span className="text-white">Биробиджан, Советская, 48</span></div>
                            <div>
                                <a href="tel:+7 (924) 640–38–66" className="phone text-white">+7 (924) 640–38–66</a>
                                <AiOutlineWhatsApp color="#5ec244" className="ms-2" />
                                <FaViber color="#8b66a8" className="ms-2" />
                                <FaTelegramPlane color="white" className="ms-2" />
                            </div>
                        </div>

                        <div style={{ whiteSpace: "nowrap" }} className="my-3 me-4">
                            <div><span className="text-white">Благовещенск, Конная, 48</span></div>
                            <div>
                                <a href="tel:+7 (924) 155–93–51640–38–66" className="phone text-white">+7 (924) 155–93–51</a>
                                <AiOutlineWhatsApp color="#5ec244" className="ms-2" />
                                <FaViber color="#8b66a8" className="ms-2" />
                                <FaTelegramPlane color="white" className="ms-2" />
                            </div>
                        </div>

                        {/* <div style={{ whiteSpace: "nowrap" }} className="my-3 me-4">
                            <div className="social-media">
                                <a href="https://www.youtube.com/channel/UCk6fP73hQZQAmMEOhV01Rbw" target="blank" className="email mr-3 smmm-link">
                                    <AiFillYoutube color="red" size={"1.5em"} />
                                    <AiOutlineInstagram color="yellow" size={"1.5em"} className="ms-2" />
                                </a>
                                <a href="https://www.instagram.com/moto_hit27" target="blank" className="email smmm-link"><i className="fab fa-instagram text-corp-yello"></i></a>
                            </div>
                            <div style={{ whiteSpace: "nowrap" }} className="my-3 me-4"><a href="mailto:bir-moto@mail.ru" className="email text-white">bir-moto@mail.ru</a></div>
                        </div> */}
                    </div>
                    <div className="my-3 me-4">
                        <Link
                            to={"/cart"}
                            className="text-white d-flex align-items-center text-decoration-none">
                            <FaShoppingCart color="white" size="1.5em" className="me-2" />
                            {(() => {
                                const counts = cartStore.map(product => product.count);
                                const sum = counts.length ? counts.reduce((a, b) => a + b) : 0;
                                return <span>X {sum}</span>;
                            })()}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-3 " style={{ background: "#eee" }}>
            <div className="container">
                <div >
                    <Categories />
                </div>
            </div>
        </div>
    </header>
}
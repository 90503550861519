import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { CreateProduct } from './tools/modules/createProduct/createProduct';
import { Header } from './tools/modules/header/header';
import { Product } from './tools/modules/product/product';
import { Products } from './tools/modules/products/products';
import { CategoryPage } from './tools/routes/category-page/category-page';
import { CategoriesPage } from './tools/routes/categories-page/categories-page';
import { EditProduct } from './tools/routes/editProduct/editProduct';
import { LoginRoute } from "./tools/routes/login";
import { Cart } from './tools/routes/cart/cart';
import { Search } from './tools/routes/search/search';
import { Orders } from './tools/routes/orders/orders';
import { Login } from './tools/routes/login/login';
import { $auth } from "./tools/store/auth";
import { useStore } from 'effector-react';
import { Investmens } from './tools/modules/Investmens/Investmens';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { AuthChecker } from './tools/AuthChecker';
import { FaArrowUp } from 'react-icons/fa';

function App() {
  const auth = useStore($auth);
  return <AuthChecker>
    <BrowserRouter>

      <Header />

      <div className="container">
        <Routes>
          <Route path="/" element={<div>
            <h1>зип.мотохит27.рф</h1>
            <div>
              <Products />
            </div>
          </div>} />

          <Route path="/product/:id" element={<Product />} />


          <Route path="/auth" element={<LoginRoute />} />

          <Route path="/category/:id" element={<CategoryPage />} />

          {auth && <>
            <Route path="/edit-product/:id" element={<EditProduct />} />
            <Route path="/create-product" element={<div>
              <h1>Создать товар</h1>
              <div>
                <CreateProduct />
              </div>
            </div>} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/orders" element={<Orders />} />
          </>}

          <Route path="/cart" element={<Cart />} />
          <Route path="/search" element={<Search />} />

          <Route path="/login" element={<Login />} />

          {auth && <Route path="/investments" element={<Investmens />} />}

        </Routes>
      </div>

    </BrowserRouter>
    <ToastContainer />
    <div
      style={{
        position: "fixed",
        right: 10,
        bottom: 10
      }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    ><FaArrowUp size={50} /></div>
  </AuthChecker>
}



export default App;

import { Link } from "react-router-dom"
import { $auth, logout } from "../../store/auth"
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useStore } from "effector-react";
import url from "../../url";

export const Menu = () => {
    const auth = useStore($auth);

    return <div className="d-flex flex-wrap">
        <Link to="/" className="me-2 my-2 text-nowrap d-block text-white me-2">Главная</Link>
        <Link to="/create-product" className="me-2 my-2 text-nowrap d-block text-white me-2">Создать товар</Link>
        <Link to="/categories" className="me-2 my-2 text-nowrap d-block text-white me-2">Категории</Link>
        <Link to="/search" className="me-2 my-2 text-nowrap d-block text-white me-2">Поиск</Link>
        <Link to="/orders" className="me-2 my-2 text-nowrap d-block text-white me-2">Заказы</Link>

        {Number(auth?.user?.id) === 1
            ? <button
                onClick={() => {
                    console.log(url);

                    // alert();
                    if (url)
                        fetch(url + "?service=php_stock_sender")
                            .then(() => {
                                alert('Проверьте почту')
                            })
                }}
                className="me-2 text-nowrap d-block me-2 btn btn-sm btn-warning text-dark">Отчет</button>
            : null}

        <button className="btn btn-danger btn-sm d-block"
            onClick={() => {
                confirmAlert({
                    message: 'Вы хотите выйти?',
                    buttons: [
                        {
                            label: 'Да',
                            onClick: () => {
                                logout();
                            }
                        },
                        {
                            label: 'Нет',
                            // onClick: () => alert('Click No')
                        }
                    ]
                });
            }}
        >Выйти</button>
    </div>
}
import { useStore } from "effector-react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fetcher } from "../../fetcher";
import { ProductCard } from "../../modules/productCard/productCard";
import { ProductInt } from "../../store/products";
import { $categoryStore, setCategory } from "./store";

export const CategoryPage = () => {
    const categoryStore = useStore($categoryStore);

    let params = useParams();
    let categoryID = params?.id;
    useEffect(() => {
        fetcher.get_category({
            category: categoryID
        }).then((x: any) => {
            if (x?.data) {
                setCategory(x.data)
            } else {
                alert("что-то пошло не так");
            }

        })
    }, [categoryID]);

    return <>
        <h1>{categoryStore?.name}</h1>
        <div >
            {categoryStore?.children?.length ? <div className="border d-inline-block p-3 mb-3">
                <strong>Подкатегории</strong>
                <ul>
                    {categoryStore.children.map((subcategory, i) => <li key={i}><Link className="text-dark" to={`/category/${subcategory.slug}`}>{subcategory.name}</Link></li>)}
                </ul>
            </div> : null}
        </div>
        {categoryStore?.description ? <div className="py-2 my-2">
            <strong>Описание</strong>
            <div dangerouslySetInnerHTML={{ __html: categoryStore.description }} />
        </div> : null}
        <div>
            {(() => {
                if (!categoryStore?.products) return <>Загрузка...</>
                if (!categoryStore?.products?.length) return <>В этой категории нет товаров</>
                return <div>
                    <Products products={categoryStore.products} />
                </div>
            })()}
        </div>
    </>;
}

const Products = (props: { products: ProductInt[] }) => {
    return <>
        <div className="row">
            {props.products.map((product, index) => <ProductCard product={product} key={index} />)}
        </div>
    </>
}
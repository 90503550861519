import { useState, useEffect, Fragment } from "react";
import { fetcher } from "../../fetcher";
import Modal from 'react-modal';
import { Field, Form } from "react-final-form";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import { DescriptionEditor } from "../../modules/descriptionEditor/descriptionEditor";
import { toast } from "react-toastify";

const setCategories = createEvent();
const $categories = createStore<any>([]).on(setCategories, (_, v) => v);


export const CategoriesPage = () => {
    const state = useStore($categories);

    useEffect(() => {
        fetcher.get_categories().then(x => {
            if (x?.data) {
                setCategories(x.data);
            }
        })
    }, []);

    const rebuildCategories = (() => {
        const cats = state;

        const workObj: any = {};

        function getParents(cat: any) {
            const arr = [];
            (function rec(category_id) {
                const category: any = cats.find((cat: any) => cat.id === category_id);
                arr.push(category.id);
                if (category.parent) {
                    rec(category.parent)
                }
            })(cat);
            return arr.reverse();
        }

        cats.forEach((element: any) => {

            if (element.parent) {
                const parents = getParents(element.parent);
                let link = workObj;
                parents.forEach((parent, i) => {

                    if (i === 0) { //родитель первый
                        if (!workObj[parent]) {//в корне нет родителя, создаем
                            workObj[parent] = {
                                ...cats.find((cat: any) => cat.id === parent),
                                children: {}
                            }
                        }
                        link = workObj[parent]; //сохраняем ссылку на текущего родителя
                    }

                    if (i > 0) { //родитель не первый
                        if (!link.children[parent]) { //у родителя нет такой категории
                            link.children[parent] = {
                                ...cats.find((cat: any) => cat.id === parent),
                                children: {}
                            }

                        }
                        link = link.children[parent];
                    }
                });

                if (!link.children[element.id]) {
                    link.children[element.id] = {
                        ...element,
                        children: {}
                    }
                }

            } else {
                workObj[element.id] = {
                    ...element,
                    children: {}
                };
            }

        });

        return workObj;
    })();
    return <>
        <h1>Категории</h1>

        {(() => {

            function req(category: any) {
                return <li><div>
                    <div >
                        <CategoryWrapper category={category} />


                    </div>
                    <div>
                        <>{(() => {
                            const cats = Object.values(category.children);
                            if (cats.length === 0) return null;
                            return <ul>{cats.map((category: any, i) => <Fragment key={i}>{req(category)}</Fragment>)}</ul>;
                        })()}</>
                    </div>
                </div></li>
            }

            const rootCats =
                Object.values(rebuildCategories)
                    .map((category: any, i) => <Fragment key={i}>{req(category)}</Fragment>)
                ;
            return <ul>
                {rootCats}
            </ul>;
        })()}


        <h3>Создать категорию</h3>
        <Form

            mutators={{
                setDescription: (args, state, utils) => {
                    utils.changeValue(state, 'description', () => args)
                },
            }}

            onSubmit={(values) => {
                const formData = new FormData();
                Object.entries(values).forEach((r: any) => {
                    if (r[0] === "image") {
                        formData.append(r[0], r);
                        return null;
                    }
                    formData.append(r[0], r[1]);
                });
                fetcher.create_category(formData).then(x => {
                    if (x?.data?.success) {
                        alert('успешно');
                    } else {
                        alert("Что-то пошло не так... " + JSON.stringify(x?.data?.errors))
                    }
                    console.log('xxx', x?.data?.success);

                });
            }}
            render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit} style={{ maxWidth: "500px" }}>
                    <div>
                        <strong>Название</strong>
                        <Field name="name" >{(props: any) => (<div><input {...props.input} className="form-control" /></div>)}</Field>
                    </div>
                    <div className="mt-3">
                        <strong>Родительская категория</strong>
                        <Field name="parent" component="select">
                            {({ input }) =>
                                <select {...input} className="form-control form-select" >
                                    <option value="">Выберите категорию</option>
                                    {Array.isArray(state) && state
                                        // .filter(parent => parent.id !== state.id)
                                        .map((x: any) =>
                                            <option value={x.id} key={x.slug}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>
                    </div>
                    <div className="mt-3">
                        <strong>Описание</strong>
                        <Field name="description" req>{props => (<div><DescriptionEditor
                            setValue={form.mutators.setDescription}
                            placeholder="Введите описание"
                        /></div>)}</Field>
                    </div>
                    <button className="btn btn-primary btn-sm mt-2">Сохранить</button>
                </form>
            )}
        />
    </>
}

const CategoryWrapper = ({ category }: any) => {

    const categories = useStore($categories);

    const [viewButtons, setViewButtons] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }


    return <div
        onMouseOver={() => { setViewButtons(true) }}
        onMouseLeave={() => { setViewButtons(false) }}
        style={{ height: "35px" }}
        className="d-flex align-items-center"
    >
        <span className="me-3">{category.name}</span>
        {viewButtons &&
            <div className="d-flex">
                <button onClick={() => openModal()} className="btn btn-sm btn-primary">изменить</button>
                {/* <button onClick={() => alert('пока не работает')} className="btn btn-sm btn-danger ms-2">удалить</button> */}
            </div>
        }

        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Form
                initialValues={(() => {
                    const obj: any = {
                        id: category.id,
                        name: category.name,
                        description: category.description,
                    };
                    if (category.parent) obj['parent'] = category.parent;
                    return obj;
                })()}
                mutators={{
                    setDescription: (args, state, utils) => {
                        utils.changeValue(state, 'description', () => args)
                    },
                }}
                onSubmit={(values) => {
                    const formData = new FormData();
                    Object.entries(values).forEach((r: any) => {
                        if (r[0] === "image") {
                            formData.append(r[0], r);
                            return null;
                        }
                        formData.append(r[0], r[1]);
                    });
                    fetcher.edit_category(formData).then(x => {
                        if (x?.data?.res) {
                            toast.success("Успешно");
                            closeModal();
                            fetcher.get_categories().then(x => {
                                if (x?.data) {
                                    setCategories(x.data);
                                }
                            })
                        }
                    });
                }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        {/* {JSON.stringify(values)} */}
                        <div className="">
                            <div>
                                <strong>Название категории</strong>
                                <Field name="name" req>{(props: any) => (<div><input {...props.input} className="form-control" /></div>)}</Field>
                            </div>
                            <div className="mt-3">
                                <strong>Родительская категория</strong>
                                <Field name="parent" component="select">
                                    {({ input }) =>
                                        <select {...input} className="form-control form-select" >
                                            <option value="">Выберите категорию</option>
                                            {Array.isArray(categories) && categories
                                                .filter(parent => parent.id !== category.id)
                                                .map((x: any) =>
                                                    <option value={x.id} key={x.slug}>{x.name}</option>
                                                )}
                                        </select>}
                                </Field>
                            </div>
                        </div>
                        {<div>
                            <strong>Описание</strong>

                            <DescriptionEditor
                                initialValue={category.description && category.description}
                                setValue={form.mutators.setDescription}
                            />
                        </div>}
                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary btn-sm ">сохранить</button>
                            <button onClick={closeModal} className="btn btn-outline-danger btn-sm ms-2">отмена</button>
                        </div>

                    </form>
                )}
            />
        </Modal>

    </div>
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('body');
import { useEffect, useState } from "react";
import { fetcher } from "../../fetcher"

export const Investmens = () => {
    const [insestmentsSum, setSum] = useState(0);
    const [data, setData] = useState<any>(null);
    useEffect(() => {
        fetcher.get_investments().then(x => {
            setSum(x.data.sum);
            setData(x.data);
        });
    }, []);
    return <>
        <h3>Вложено</h3>
        <div>
            {
                insestmentsSum
                    ? `${insestmentsSum.toLocaleString()}  ₽`
                    : 'Загрузка...'
            }
        </div>
        <div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>категория</th>
                        <th>id</th>
                        <th>вложено</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.categories?.map((category: {
                        "id": string,
                        "slug": string,
                        "name": string,
                        "parent": string,
                        "create_date": string,
                        "description": string,
                        "sum": number,
                    }, i: number) => <tr key={i}>
                            <td>{category.name}</td>
                            <td>{category.id}</td>
                            <td>{category.sum.toLocaleString()}</td>
                        </tr>)}
                </tbody>
            </table>
            {/* <pre>{JSON.upstringify(data, null, " ")}</pre> */}
        </div>
    </>
}
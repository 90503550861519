import { useStore } from "effector-react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fetcher } from "../../fetcher";
import url from "../../url";
import { $product, setProduct } from "./store";

import noimageSrc from "../../../media/noimage.png";
import { $cartStore, cartStoreAPI } from "../../routes/cart/store";
import { FaCartPlus } from "react-icons/fa";
import styled, { css } from "styled-components";

import { $auth } from "../../store/auth";
// import {produc}
// import { $auth } from "../../store/auth";

import { ProductInt } from "../../store/products"
import { confirmAlert } from "react-confirm-alert";
import { toast } from 'react-toastify';



// console.log('ProductInt', ProductInt);


function get_data(productID: any) {
    fetcher.get_product({
        product: productID
    }).then(x => {
        if (x?.data) {
            setProduct(x.data)
        } else {
            alert("что-то пошло не так");
        }
    })
}

export const Product = () => {

    const auth = useStore($auth);

    let params = useParams();
    let productID = params?.id;

    useEffect(() => {
        get_data(productID)
    }, [productID]);


    const product = useStore($product);
    const cartStore = useStore($cartStore);

    if (!product) return <>Загрузка...</>;
    const image = product.image_preview ? `${url}images/${product.image_preview}` : noimageSrc;

    return <div >
        <h1>{product.name}</h1>
        <div >
            <Background url={image} />
        </div>
        <div>
            <div><strong>Код товара</strong> {product.code}</div>
            <div><strong>Активен</strong> {Number(product.archive) ? "нет" : "да"}</div>
            <div><strong>Категория</strong> {product.category_name}</div>




            {auth && <>
                <div><strong>Закупочная цена</strong> {product.purchase_price}</div>
                <div>
                    <strong>Тип себестоимости</strong> {product.cost_type}
                </div>
                <div>
                    <strong>Значение себестоимости</strong> {product.cost_value}
                </div>
                <div>
                    <strong>Себестоимость</strong>{' '}
                    <>
                        {(() => {
                            let first_cost = null; if (product.cost_type === "percent") { first_cost = Number(product.purchase_price) * Number(product.cost_value) }; if (product.cost_type === "handle") { first_cost = Number(product.cost_value) }; if (product.cost_type === "fix") { first_cost = Number(product.purchase_price) + Number(product.cost_value) };
                            return first_cost;
                        })()}
                    </>
                </div>
                <div>
                    <strong>Тип розничной цены</strong> {product.retail_price.price_type}
                </div>
                <div>
                    <strong>Значение розничной цены</strong> {product.retail_price.price_count}
                </div>
                <div><strong>Комментарий</strong> {product.note}</div>
                <div>
                    <strong>Продано в Ха</strong> {product.sold_khv}
                </div>
                <div>
                    <strong>Продано в Бир</strong> {product.sold_bir}
                </div>
            </>}

            <div>
                <div>
                    <strong>Цена </strong>
                    {getPriceFromProduct(product)}
                </div>
            </div>
            <div>
                {[
                    ["В наличии в Хабаровске", "khv"],
                    ["В наличии в Биробиджане", "bir"],
                ].map((city, i) => <div key={i}>
                    <strong>{city[0]} </strong>
                    {(() => {
                        const stock_item = product.stock
                            .find(({ shop_id }) => shop_id === city[1]);
                        return <>{stock_item?.count ? stock_item?.count : 0}</>;
                    })()}
                </div>)}
            </div>
            <div><strong>Описание</strong> <pre>{product.description}</pre></div>
        </div>
        {auth && product && <div>
            <div className="my-2 d-flex">
                <Link to={`/edit-product/${product.slug}`} className="btn btn-sm btn-warning me-2">изменить</Link>
                {Number(auth?.user.role) === 1 && <button onClick={() => {
                    confirmAlert({
                        message: 'Вы хотите удалить товар?',
                        buttons: [
                            {
                                label: 'Да',
                                onClick: () => {
                                    fetcher.delete_product({ product_id: product.id }).then(x => {
                                        if (x?.data?.success) {
                                            fetcher.get_product({
                                                product: productID
                                            }).then(x => {
                                                if (x?.data) {
                                                    setProduct(x.data)
                                                } else {
                                                    toast.error('Что-то пошло не так ');
                                                }
                                            })
                                        }
                                    })
                                }
                            },
                            {
                                label: 'Нет',
                                // onClick: () => alert('Click No')
                            }
                        ]
                    });
                }} className="btn btn-sm btn-danger">удалить</button>}
            </div>
            <div className="mt-2 mb-4">
                <div>
                    <button className="btn btn-sm btn-primary mt-2 me-2"
                        onClick={() => {
                            fetcher.sell({ product: product.id, shop: "khv", price: getPriceFromProduct(product) }).then(x => {
                                if (x?.data?.success) {
                                    toast.success("Успешно");
                                    get_data(productID);
                                } else {
                                    toast.error("Что-то пошло не так");
                                }
                            });
                        }}
                    >Продать в Хабаровске</button>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary mt-2"
                        onClick={() => {
                            fetcher.sell({ product: product.id, shop: "bir", price: getPriceFromProduct(product) }).then(x => {
                                if (x?.data?.success) {
                                    toast.success("Успешно");
                                    get_data(productID);
                                } else {
                                    toast.error("Что-то пошло не так");
                                }
                            });
                        }}
                    >Продать в Биробиджане</button>
                </div>

                <div>
                    <button className="btn btn-sm btn-primary mt-2"
                        onClick={() => {
                            fetcher.sell({ product: product.id, shop: "blg", price: getPriceFromProduct(product) }).then(x => {
                                if (x?.data?.success) {
                                    toast.success("Успешно");
                                    get_data(productID);
                                } else {
                                    toast.error("Что-то пошло не так");
                                }
                            });
                        }}
                    >Продать в Благовещенске</button>
                </div>
            </div>
        </div>}
        <div className="my-2 d-flex align-items-center">
            {(() => {
                const stocks = product.stock.map(x => Number(x.count));
                const in_stock = stocks.length ? stocks.reduce((a, b) => a + b) : 0;
                if (in_stock) {
                    return <button className="btn btn-sm btn-primary"
                        onClick={() => {
                            cartStoreAPI.addProduct(product);
                        }}
                    >В корзину</button>
                }
            })()}
            {/* <button className="btn btn-sm btn-primary"
                onClick={() => {
                    cartStoreAPI.addProduct(product);
                }}
            >В корзину</button> */}
            {(() => {
                const currentProduct = cartStore.find(cartProduct => cartProduct.slug === product.slug);
                if (currentProduct) {
                    return <div className="ms-3 d-flex align-items-center">
                        <FaCartPlus />
                        <span style={{ fontSize: "1.5em" }}>{currentProduct.count}</span>
                    </div>;
                }
                return null;
            })()}

        </div>

    </div>
}

export function getPriceFromProduct(product: ProductInt) { //вынести в отдельный файл
    const first_cost = (() => {
        if (product != null) {
            let first_cost = null; if (product.cost_type === "percent") { first_cost = Number(product.purchase_price) * Number(product.cost_value) }; if (product.cost_type === "handle") { first_cost = Number(product.cost_value) }; if (product.cost_type === "fix") { first_cost = Number(product.purchase_price) + Number(product.cost_value) };
            return first_cost;
        }
    })();

    let retail_cost = null;
    if (first_cost && product.retail_price) {
        const shop_price_item: any = product.retail_price;
        if (shop_price_item.price_type === "percent") {
            retail_cost = Number(first_cost) * Number(shop_price_item.price_count)
        };
        if (shop_price_item.price_type === "handle") { retail_cost = Number(shop_price_item.price_count) };
        if (shop_price_item.price_type === "fix") { retail_cost = Number(first_cost) + Number(shop_price_item.price_count) };
        return Math.ceil(retail_cost ? retail_cost / 50 : 0) * 50;
    }
}

const Background: any = styled.div`
    ${(props: any) => {
        return css`
        width: 300px;
        height: 300px;
        background: #eee;
        background-position: center;
        background-size: cover;
        background-image: url(${props.url})
    `}}
`
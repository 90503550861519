import { useStore } from "effector-react";
import { useEffect } from "react";
import { fetcher } from "../../fetcher";
import { $productsStore, setProducts } from "../../store/products";
import ReactPaginate from 'react-paginate';
import { createApi, createStore } from "effector";
import { ProductCard } from "../productCard/productCard";

const $queryParams = createStore<any>({
    page: 0,
});

export async function getDataFrom() {
    const queryParams = $queryParams.getState();
    (async () => {
        const response = await fetcher.get_products(queryParams);
        setProducts(response.data)
    })();
}

export const queryParamsAPI: any = createApi($queryParams, {
    updatePage: (state: any, offset: any): any => {
        return {
            ...state,
            page: offset?.selected ? offset?.selected : 0
        };
    },
});

export const Products = () => {
    const queryParams = useStore($queryParams);
    useEffect(() => {
        getDataFrom();

    }, [queryParams]);
    const productsStore = useStore($productsStore);

    return <div>
        <h3 className="mt-3">Все товары ({productsStore?.total})</h3>
        <div className="my-5">
            {Number(productsStore?.pages) > 1 && <ReactPaginate
                breakLabel="..."
                onPageChange={(page) => {
                    queryParamsAPI.updatePage(page);
                }}
                pageRangeDisplayed={5}
                pageCount={Number(productsStore?.pages)}
                previousLabel={null}
                nextLabel={null}
                breakClassName="breakClassName"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                pageLinkClassName="page-link"
                pageClassName="page-item"
                activeClassName="active"
            />}
        </div>


        <div className="row">
            {productsStore?.products?.map((product, index) => <ProductCard product={product} key={index} />)}
        </div>
    </div>
}
import { createApi, createStore } from "effector";

interface leadInterface {
    orders: {
        id: string;
        create_date: string;
        name: string;
        address: string;
        contacts: string;
        comment: string;
        status: string;
        products: {
            name: string;
            slug: string;
            price: number;
            count: number;
        }[]
    }[];
    per_page: string;
    total: string;
    pages: string;
}

export const $ordersStore = createStore<leadInterface | null>(null);

export const ordersStoreApi = createApi($ordersStore, {
    setStore: (_, newVal) => {
        return newVal;
    }
});

export const $queryParams = createStore<any>({
    page: 0,
});

export const queryParamsAPI: any = createApi($queryParams, {
    updatePage: (state: any, offset: any): any => {
        return {
            ...state,
            page: offset?.selected ? offset?.selected : 0
        };
    },
});

import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react';
import { useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { fetcher } from '../../fetcher'
import { DescriptionEditor } from '../descriptionEditor/descriptionEditor';
import { toast } from 'react-toastify';

const setCategories = createEvent();
const $categories = createStore(null).on(setCategories, (a, b) => {
    return b;
});

export const CreateProduct = () => {
    const categories = useStore($categories);
    useEffect(() => {
        (async () => {
            const { data } = await fetcher.get_categories();
            setCategories(data);
        })();
    }, []);

    return <>
        <ProductForm categories={categories} />
    </>
}


const ProductForm = ({ categories }: any) => (
    <Form
        initialValues={{
            ...(() => {
                if (process.env.NODE_ENV === "development") {
                    return {
                        category_id: "27",
                        name: "суперзапчасть" + Date.now(),
                        code: "12-432-св",
                        purchase_price: "1200",
                        cost_type: "percent",
                        cost_value: "1.3",
                        note: "какой-то коммент",
                        archive: "0",
                        retail_price_type: "percent",
                        retail_price_value: "1.4",
                        stock_khv: "17",
                        stock_bir: "18",
                        description: "текст\nшмекст\nпекст",
                    }
                }
                return {}
            })(),


        }}
        onSubmit={onSubmit}

        mutators={{
            setDescription: (args, state, utils) => {
                utils.changeValue(state, 'descript', () => args)
            },
        }}

        render={({ form, handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
                <div className="">
                    <FormSection
                        name="Описание товара"
                        component={<Field name="descript" req>{props => (<div><DescriptionEditor
                            setValue={form.mutators.setDescription}
                        /></div>)}</Field>}
                    />
                    <FormSection
                        name="Наименование товара"
                        component={<Field name="name" req>{props => (<div><input {...props.input} placeholder="Наименование" className="form-control" /></div>)}</Field>}
                    />
                    <FormSection
                        name="Категория"
                        component={<Field name="category_id" component="select">
                            {({ input }) =>
                                <select {...input} className="form-control form-select" >
                                    <option value="">Выберите категорию</option>
                                    {Array.isArray(categories) && categories
                                        .map((x: any) =>
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>}
                    />
                    <FormSection
                        name="Артикул"
                        component={<Field name="code" req>{props => (<div><input {...props.input} placeholder="Артикул" className="form-control" /></div>)}</Field>}
                    />
                    <FormSection
                        name="Закупочная цена"
                        component={<Field name="purchase_price" req>{props => (<div><input {...props.input} placeholder="Закупочная цена" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Тип себестоимости"
                        component={<Field name="cost_type" component="select">
                            {({ input }) =>
                                <select {...input} className="form-control form-select" >
                                    <option value="">Выберите тип себестоимости</option>
                                    {[
                                        { id: "percent", name: "Процент" },
                                        { id: "handle", name: "Ручной" },
                                        { id: "fix", name: "Фиксированный" },
                                    ]
                                        .map((x: any) =>
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>}
                    />
                    <FormSection
                        name="Значение себестоимости"
                        component={<Field name="cost_value" req>{props => (<div><input {...props.input} placeholder="Значение себестоимости" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Комментарий"
                        component={<Field name="note" req>{props => (<div><input {...props.input} placeholder="Комментарий" className="form-control" /></div>)}</Field>}
                    />
                    <FormSection
                        name="Изображение"
                        component={<FileField name="image" />}
                    />
                    <FormSection
                        name="В архиве"
                        component={<Field name="archive" component="select" >
                            {({ input }) =>
                                <select {...input} className="form-control form-select" aria-label="Default select example">
                                    <option value="">Выберите статус товара</option>
                                    {[
                                        { id: "1", name: "В архиве" },
                                        { id: "0", name: "В работе" },
                                    ]
                                        .map((x: any) =>
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        )}
                                </select>}
                        </Field>}
                    />

                    <>
                        <FormSection
                            name="Тип розничной цены"
                            component={<Field name="retail_price_type" component="select" >
                                {({ input }) =>
                                    <select {...input} className="form-control form-select" aria-label="Default select example">
                                        <option value="">Выберите тип</option>
                                        {[
                                            { id: "percent", name: "Процент" },
                                            { id: "handle", name: "Ручной" },
                                            { id: "fix", name: "Фиксированный" },
                                        ]
                                            .map((x: any) =>
                                                <option value={x.id} key={x.id}>{x.name}</option>
                                            )}
                                    </select>}
                            </Field>}
                        />
                        <FormSection
                            name="Значение розничной цены"
                            component={<Field name="retail_price_value" req>{props => (<div><input {...props.input} placeholder="" className="form-control" type={"number"} /></div>)}</Field>}
                        />
                    </>

                    <FormSection
                        name="В наличии в Хабаровске"
                        component={<Field name="stock_khv" req>{props => (<div><input {...props.input} placeholder="В наличии в Хабаровске" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="В наличии в Биробиджане"
                        component={<Field name="stock_bir" req>{props => (<div><input {...props.input} placeholder="В наличии в Биробиджане" className="form-control" type={"number"} /></div>)}</Field>}
                    />
                    <FormSection
                        name="Описание"
                        component={<Field name="description" req>{props => (<div><textarea {...props.input} placeholder="Описание" className="form-control" /></div>)}</Field>}
                    />

                </div>
                <button type="submit" className="btn btn-primary btn-sm mt-2">Сохранить</button>
            </form>
        )}
    />
)

export const FileField = ({ name, ...props }: any) => (
    <Field<FileList> name={name}>
        {({ input: { value, onChange, ...input } }) => (
            <input
                {...input}
                type="file"
                onChange={({ target }) => onChange(target.files)} // instead of the default target.value
                {...props}
            />
        )}
    </Field>
)


const FormSection = ({ name, component }: any) => {
    return <div className="row mb-3">
        <div className="col-md-2 lh-1">
            <strong>{name}</strong>
        </div>
        <div className="col-md-4">
            {component}
        </div>
    </div>
}

function onSubmit(values: any) {
    const formData = new FormData();
    Object.entries(values).forEach((r: any) => {
        if (r[0] === "image") {
            formData.append(r[0], r[1][0]);
            return null;
        }
        formData.append(r[0], r[1]);
    })
    fetcher.create_product(formData).then(res => {
        if (res?.data?.success) {
            toast.success("Успешно!")
        } else {
            toast.success("Успешно!")
        }
    });
}

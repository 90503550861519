import { useStore } from "effector-react";
import { useEffect } from "react";
import { fetcher } from "../../fetcher";
import { $ordersStore, $queryParams, ordersStoreApi, queryParamsAPI } from "./store";
import ReactPaginate from 'react-paginate';
import { toast } from "react-toastify";

export async function getDataFrom() {
    const queryParams = $queryParams.getState();
    (async () => {
        fetcher.get_orders(queryParams).then(x => {
            if (x?.data) {
                ordersStoreApi.setStore(x.data);
            } else {
                toast.error("Что-то пошло не так");
            }
        })
    })();
}

export const Orders = () => {
    const ordersStore = useStore($ordersStore);
    const queryParams = useStore($queryParams);
    useEffect(() => {
        getDataFrom();
    }, [queryParams]);
    return <>
        <h1>Заказы</h1>
        {Number(ordersStore?.pages) > 1 && <ReactPaginate
            breakLabel="..."
            onPageChange={(page) => {
                queryParamsAPI.updatePage(page);
            }}
            pageRangeDisplayed={5}
            pageCount={Number(ordersStore?.pages)}
            previousLabel={null}
            nextLabel={null}
            breakClassName="breakClassName"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            pageLinkClassName="page-link"
            pageClassName="page-item"
            activeClassName="active"
        />}
        <table className="table table-sm table-bordered table-striped">
            <thead>
                <tr>
                    <td className="text-nowrap">ID</td>
                    <td className="text-nowrap">дата</td>
                    <td className="text-nowrap">статус</td>
                    <td className="text-nowrap">ФИО клиента</td>
                    <td className="text-nowrap">адрес</td>
                    <td className="text-nowrap">контакты</td>
                    <td className="text-nowrap">комментарий клиента</td>
                    <td className="text-nowrap">товары</td>
                    <td />
                </tr>
            </thead>
            <tbody>
                {ordersStore?.orders?.map((order, i) => <tr key={i}>
                    <td className="text-nowrap">{order.id}</td>
                    <td className="text-nowrap">{order.create_date}</td>
                    <td className="text-nowrap">{(() => {
                        switch (order.status) {
                            case "pending":
                                return "в ожидании";
                            case "decline":
                                return "отменен";
                            case "done":
                                return "обработан";
                            default:
                                return `статус неизвестен ${order.status}`
                        }
                    })()}</td>
                    <td className="text-nowrap">{order.name}</td>
                    <td>{order.address}</td>
                    <td className="text-nowrap">{order.contacts}</td>
                    <td>{order.comment}</td>
                    <td className="text-nowrap">
                        <table className="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>товар</th>
                                    <th>цена</th>
                                    <th>к-во</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.products.map((product, i) => {
                                    return <tr key={i}>
                                        <td>{product.name}</td>
                                        <td>{product.price}</td>
                                        <td>{product.count}</td>
                                    </tr>
                                })}
                                <tr>
                                    <th>Итого:</th>
                                    <td>{order.products
                                        .map(product => product.count * product.price)
                                        .reduce((a, b) => a + b)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        {order.status === "pending" &&
                            <>
                                <button className="btn btn-sm btn-outline-dark text-nowrap mb-2"
                                    onClick={() => {
                                        fetcher.confirm_order({ order: order.id }).then(x => {
                                            if (x?.data?.success) {
                                                toast.success("Заказ подтвержден");
                                                getDataFrom();
                                            } else {
                                                toast.error("Что-то пошло не так");
                                            }
                                        })
                                    }}
                                >провести заказ</button>
                                <button className="btn btn-sm btn-outline-dark text-nowrap"
                                    onClick={() => {
                                        fetcher.decline_order({ order: order.id }).then(x => {
                                            if (x?.data?.success) {
                                                toast.success("Заказ отменен");
                                                getDataFrom();
                                            } else {
                                                toast.error("Что-то пошло не так");
                                            }
                                        })
                                    }}
                                >отменить заказ</button>
                            </>
                        }
                    </td>
                </tr>)}
            </tbody>
        </table>
    </>;
}

import { createEvent, createStore } from 'effector';

export const setProducts = createEvent<any>();



export interface ProductInt {
    id: string;
    category_name: string;
    category_id: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    purchase_price: string;
    image: string;
    image_preview: string;
    cost_type: string;
    cost_value: string;
    note: string;
    archive: string;
    birm_products: string;
    title_color: string;
    sold_khv: string;
    sold_bir: string;
    retail_price: {
        price_type: string;
        price_count: string;
    };
    stock: {
        id: string;
        shop_id: string;
        product_id: string;
        count: string;
    }[]
}

export const $productsStore = createStore<
    null |
    { products: ProductInt[]; total: string | number; pages: string | number; page: string | number }
>(null)
    .on(setProducts, (_, n) => n);

$productsStore.watch(r => {
});
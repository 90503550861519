import { createEvent, createStore } from "effector";
import { ProductInt } from "../../store/products";

export const a = null;


export const setCategory = createEvent();
export const $categoryStore = createStore<null | CategoryInterface>(null).on(setCategory, (_, v) => v);


type CategoryInterface = {
    name: string;
    slug: string;
    children: {
        name: string;
        slug: string;
    }[];
    products: ProductInt[];
    description?: string;
}
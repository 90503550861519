import { createEvent, createStore, createEffect } from 'effector'
// import { fetcher } from '../fetcher';

export const insertAuth = createEvent<any>();

export const logout = createEffect(async () => {
    localStorage.clear();
    insertAuth(null);
});

export const setAuth = createEffect(async (some: any) => {
    insertAuth(some);
});

export const $auth = createStore<null | { user: any }>(null)
    .on(insertAuth, (_, n) => n);

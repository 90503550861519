import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { fetcher } from "../../fetcher"
import { SearchPanel } from "./searchPanel";

// TODO категории переименовать в меню

export const Categories = () => {
    const [state, setState] = useState<any>([]);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetcher.get_categories().then(x => {
            if (x?.data) {
                setState(x.data);
            }
        })
    }, []);

    const rebuildCategories = (() => {
        const cats = state;

        const workObj: any = {};

        function getParents(cat: any) {
            const arr = [];
            (function rec(category_id) {
                const category: any = cats.find((cat: any) => cat.id === category_id);
                arr.push(category.id);
                if (category.parent) {
                    rec(category.parent)
                }
            })(cat);
            return arr.reverse();
        }

        cats.forEach((element: any) => {

            if (element.parent) {
                const parents = getParents(element.parent);
                let link = workObj;
                parents.forEach((parent, i) => {

                    if (i === 0) { //родитель первый
                        if (!workObj[parent]) {//в корне нет родителя, создаем
                            workObj[parent] = {
                                ...cats.find((cat: any) => cat.id === parent),
                                children: {}
                            }
                        }
                        link = workObj[parent]; //сохраняем ссылку на текущего родителя
                    }

                    if (i > 0) { //родитель не первый
                        if (!link.children[parent]) { //у родителя нет такой категории
                            link.children[parent] = {
                                ...cats.find((cat: any) => cat.id === parent),
                                children: {}
                            }

                        }
                        link = link.children[parent];
                    }
                });

                if (!link.children[element.id]) {
                    link.children[element.id] = {
                        ...element,
                        children: {}
                    }
                }

            } else {
                workObj[element.id] = {
                    ...element,
                    children: {}
                };
            }

        });

        return workObj;
    })();

    return <>
        <div className="mb-3"><SearchPanel /></div>
        <div className="d-flex">
            <button
                className="btn btn-sm btn-dark me-5"
                onClick={() => setOpen(!open)}>{open ? "Свернуть" : "Меню"}</button>
        </div>

        <div style={{
            maxHeight: open ? "2000px" : "0px",
            overflow: "hidden",
            transition: "0.5s"
        }}>
            {(() => {

                function req(category: any) {
                    return <li><div>
                        <div>
                            <Link to={`/category/${category.slug}`} className="text-dark" onClick={() => setOpen(false)}>{category.name}</Link>
                        </div>
                        <div>
                            <>{(() => {
                                const cats = Object.values(category.children);
                                if (cats.length === 0) return null;
                                return <ul>{cats.map((category: any, i) => <Fragment key={i}>{req(category)}</Fragment>)}</ul>;
                            })()}</>
                        </div>
                    </div></li>
                }

                const rootCats =
                    Object.values(rebuildCategories)
                        .map((category: any, i) => <Fragment key={i}>{req(category)}</Fragment>)
                    ;
                return <ul>
                    <li><Link to={`/`} className="text-dark" onClick={() => setOpen(false)}>Главная</Link></li>
                    <li><a href={`https://мотохит27.рф`} className="text-dark">Техника</a></li>
                    {rootCats}
                </ul>;
            })()}
        </div>
        {open && <button
            className="btn btn-sm btn-dark"
            onClick={() => setOpen(!open)}>{open ? "Свернуть" : "Меню"}</button>}

    </>

}
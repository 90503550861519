import { useStore } from "effector-react"
import { Field, Form } from "react-final-form";
import { FaMinusCircle, FaPlusSquare, FaTrashAlt } from "react-icons/fa";
import { fetcher } from "../../fetcher";
import { $cartStore, cartStoreAPI } from "./store";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

export const Cart = () => {
     const cartStore = useStore($cartStore);

     return <>
          <h1>Корзина</h1>
          {(() => {

               if (!cartStore.length) return "Корзина пуста..."
               return <table className="table table-hover table-responsive table-sm">
                    <thead>
                         <tr>
                              <th>Товар</th>
                              <th>Количество</th>
                              <th>Цена</th>
                              <th />
                         </tr>
                    </thead>
                    <tbody>
                         {cartStore.map((product, i) => <tr key={i}>
                              <td>{product.name}</td>
                              <td>
                                   <div className="d-flex align-items-center">
                                        <FaPlusSquare size={'1.5em'} onClick={() => {
                                             cartStoreAPI.increment(product.slug);
                                        }} />
                                        <span className="mx-2" style={{ fontSize: "1.5em" }}>{product.count}</span>
                                        <FaMinusCircle size={'1.5em'}
                                             onClick={() => {
                                                  cartStoreAPI.decrement(product.slug);
                                             }}
                                        />
                                   </div>
                              </td>
                              <td>{getPriceFromProduct(product)}</td>
                              <td><FaTrashAlt color={'red'} size="1.5em" onClick={() => {
                                   confirmAlert({
                                        message: 'Вы хотите удалить товар из корзины?',
                                        buttons: [
                                             {
                                                  label: 'Да',
                                                  onClick: () => {
                                                       cartStoreAPI.removeProduct(product.slug)
                                                  }
                                             },
                                             {
                                                  label: 'Нет',
                                             }
                                        ]
                                   });
                              }} /></td>
                         </tr>)}
                         <tr>
                              <th>Сумма:
                                   <span className="ms-2" style={{ fontSize: "1.5em" }}>{(() => { const sum = cartStore.map(x => x.price * x.count).reduce((a, b) => a + b); return (Math.ceil(sum ? sum / 50 : 0) * 50); })()}</span>
                              </th>
                         </tr>
                    </tbody>
               </table>
          })()}
          {(() => {
               if (cartStore && cartStore.length) return <Form
                    initialValues={{
                         products: cartStore,
                         ...(() => {
                              if (process.env.NODE_ENV === "development") {
                                   return {
                                        name: "Дядя Вася",
                                        contacts: "Звонить на деревню к дедушке",
                                        address: "Везти тоже на деревню к дедушке",
                                        comment: "Всё нормально"
                                   }
                              }
                              return {}
                         })()
                    }}
                    onSubmit={(values) => {
                         fetcher.send_cart(values).then(x => {
                              if (x?.data?.success) {
                                   toast.success("Спасибо! Мы свяжемся с Вами в ближайшее время");
                                   cartStoreAPI.clear();
                              } else {
                                   toast.error("Что-то пошло не так #3jf8c3");
                              }
                         }).catch(() => toast.error("Что-то пошло не так #jc963v"));
                    }}
                    validate={values => {
                         const errors: any = {}
                         if (!values.name) {
                              errors.name = 'Required'
                         }
                         if (!values.contacts) {
                              errors.contacts = 'Required'
                         }
                         if (!values.address) {
                              errors.address = 'Required'
                         }
                         return errors
                    }}
                    render={({ handleSubmit, values }) => {
                         return <form onSubmit={handleSubmit} style={{ maxWidth: "500px" }}>
                              <div><strong className="d-block">ФИО</strong><Field name="name" component={"input"} style={{ width: "100%" }} /></div>
                              <div><strong className="d-block">Контакты для связи</strong><Field name="contacts" component={"input"} style={{ width: "100%" }} /></div>
                              <div><strong className="d-block">Адрес доставки</strong><Field name="address" component={"input"} style={{ width: "100%" }} /></div>
                              <div><strong className="d-block">Комментарий</strong><Field name="comment" component={"textarea"} style={{ width: "100%" }} /></div>
                              <button className="btn btn-primary btn-sm mt-2">Отправить</button>
                         </form>
                    }}
               />
               return null;
          })()}
     </>
}

function getPriceFromProduct(product: any) {
     let retail_cost = Number(product.price);
     if (retail_cost) {
          return Math.ceil(retail_cost ? retail_cost / 50 : 0) * 50;
     }
}
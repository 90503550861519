import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import { Field, Form } from "react-final-form"
import { useNavigate } from "react-router-dom"
import { getProductsSearch, setSearchInitStore } from "../../routes/search/search";

export const setHide = createEvent<boolean>();
const $hide = createStore<boolean>(false).on(setHide, (_, v) => v);


export const SearchPanel = () => {
    const hide = useStore($hide);
    const redirect = useNavigate();
    if (hide) return null;
    return <>

        <Form
            validate={values => {
                const errors: any = {}
                if (!values.keyword) {
                    errors.keyword = 'Required'
                }
                return errors;
            }}
            onSubmit={(values: { keyword: string; }) => {
                redirect("/search");
                setSearchInitStore(values);
                getProductsSearch(values);
            }}
            render={({ handleSubmit }) => {
                return <form onSubmit={handleSubmit}>
                    <div className="d-flex justify-align-center">
                        <Field component={"input"} name="keyword" placeholder="Поиск" />
                        <button className="btn btn-sm btn-primary ms-2">Поиск</button>
                    </div>
                </form>
            }}
        />
    </>
}